import React from "react"
import PropTypes from "prop-types"

const ServicesGrid = ({ title, children }) => {
  return (
    <div className="pt-10">
      <h3 className="font-bold text-sm md:text-xl text-black mb-8 md:mb-14">
        {title}
      </h3>
      <div className="-mx-3 md:mx-0">{children}</div>
    </div>
  )
}

ServicesGrid.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

ServicesGrid.defaultProps = {
  title: ``,
  children: ``,
}

export default ServicesGrid
