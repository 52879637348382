import React from "react"
import PropTypes from "prop-types"
import Heading from "../../atoms/Heading"

const ServiceItem = ({ lead, icon, justify, children }) => {
  return (
    <div className={`md:max-w-[240px] text-dark line- ${justify} `}>
      {icon}
      <Heading
        variant="h3"
        component="h4"
        classes="font-semibold mt-4 md:whitespace-pre-line min-h-[50px]"
      >
        {children}
      </Heading>
      <p className="mt-4 md:mt-8 mb-8 md:max-w-[168px] text-beige-160">
        {lead}
      </p>
    </div>
  )
}

ServiceItem.propTypes = {
  children: PropTypes.node.isRequired,
  lead: PropTypes.string.isRequired,
  icon: PropTypes.node,
}

ServiceItem.defaultProps = {
  children: ``,
  lead: ``,
  icon: ``,
}

export default ServiceItem
