import React from "react"
import PropTypes from "prop-types"
import Heading from "../../atoms/Heading"

const ServiceSection = ({
  background,
  header,
  subHeader,
  lead,
  icon,
  children,
}) => {
  return (
    <div className={`${background} py-10`}>
      <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
        <div className="flex justify-between items-end">
          <div>
            <Heading
              variant="h2"
              component="h6"
              classes="inline-block text-base font-extrabold border-b-3 border-primary mb-4"
            >
              {subHeader}
            </Heading>
            <Heading variant="h3" component="h2" classes="mb-7 md:mb-8 md:mb-0">
              {header}
            </Heading>
            <div className="mt-6 md:mt-10 lg:pr-16 max-w-prose text-base text-beige-160">
              {lead}
            </div>
          </div>
          <div className="flex-shrink-0 hidden md:block p-6">{icon}</div>
        </div>
        {children}
      </div>
    </div>
  )
}

ServiceSection.propTypes = {
  background: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired,
  lead: PropTypes.string.isRequired,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
}

ServiceSection.defaultProps = {
  background: ``,
  header: ``,
  subHeader: ``,
  lead: ``,
  icon: ``,
  children: ``,
}

export default ServiceSection
