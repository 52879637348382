import React, { useRef } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import useScrollSpy from "react-use-scrollspy"
import Slider from "react-slick"
import Svg from "react-inlinesvg"
import Seo from "../components/seo"
import ServiceSection from "../components/organisms/ServiceSection"
import Button from "../components/atoms/Button"
import TechnologyItem from "../components/atoms/TechnologyItem"
import ServiceItem from "../components/molecules/ServiceItem"
import ServicesWorkBox from "../components/molecules/ServicesWorkBox"
import DiagonalArrowIcon from "../images/icons/diagonal-arrow.svg"
import ServicesGrid from "../components/molecules/ServicesGrid"
import WorksGrid from "../components/molecules/WorksGrid"
import QuoteSection from "../components/organisms/QuoteSection"
import Brands from "../components/organisms/Brands"
import Testimonials from "../components/organisms/Testimonials"
import AuthorisedVendor from "../components/organisms/AuthorisedVendor"
import "slick-carousel/slick/slick.css"

export const query = graphql`
  {
    allServicesJson {
      nodes {
        id
        section
        icon {
          publicURL
        }
        navTitle
        navIcon {
          publicURL
        }
        intro
        subtitle
        title
        url
        technologies {
          name
          url
          icon {
            publicURL
          }
        }
        works {
          lead
          title
          url
          image {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                width: 720
              )
            }
          }
        }
        services {
          lead
          title
          icon {
            publicURL
          }
        }
      }
    }
    pageFeaturedImage: file(
      absolutePath: { glob: "**/src/images/og/featured-image-services.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 1200)
      }
    }
  }
`

const ServicesPage = ({ data }) => {
  const services = data.allServicesJson

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]

  const activeSection = useScrollSpy({
    sectionElementRefs: sectionRefs,
    offsetPx: -90,
  })

  const carouselSettings = {
    arrows: false,
    dots: false,
    swipeToSlide: true,
    infinite: true,
    draggable: true,
    autoplay: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 768,
        settings: {
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "12%",
        },
      },
    ],
  }

  return (
    <Layout headerBg="bg-beige">
      <Seo
        title="Full-cycle product development services by Peak11"
        description="You share your digital product idea and we get it done. Full-cycle product development services. UX/UI product design, mobile and web development, maintenance"
        metaFeaturedImage={
          data.pageFeaturedImage.childImageSharp.gatsbyImageData
        }
      />
      <section className="bg-beige py-10 md:py-12 relative overflow-hidden">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="flex -mx-4">
            <div className="w-full md:w-3/4 lg:w-5/6 px-4">
              <div className="relative z-10 max-w-[820px]">
                <DiagonalArrowIcon
                  className="stroke-primary mr-2"
                  width="42px"
                  height="42px"
                />
                <h1 className="text-h0/m lg:text-h0 mt-8">
                  From concept design to rock-solid software products
                </h1>
                <div className="flex flex-col-reverse md:flex-col mt-8 md:mt-10">
                  <div className="md:text-3xl text-dark mt-10 md:mt-0 max-w-[660px] leading-[1.3]">
                    Keep your vision on track - we help you plan, research,
                    design, and build a product you and your customers will
                    love. You will be actively involved in every step of the
                    way!
                  </div>
                  <div className="md:mt-14 md:mb-4">
                    <Button variant="contained" fullWidthMobile href="/contact">
                      Let's talk
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden sm:block absolute top-0 left-[55%] h-full hero-bg-pattern w-[360px]"></div>
        <div className="hidden sm:block absolute top-0 left-[55%] h-full w-full hero-bg-pattern2 ml-[360px]"></div>
      </section>

      <section className="bg-white">
        <div className="sticky top-0 md:-top-14 bg-white z-10">
          <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
            <ul className="py-3 flex space-x-1 overflow-x-auto no-scrollbar -mx-4 md:mx-0 pl-4 md:pl-0">
              {services.nodes.map((service, index) => {
                return (
                  <li
                    key={service.navTitle}
                    className="m-0 inline-flex items-center justify-center flex-grow cursor-pointer min-w-[160px] md:min-w-[180px]"
                  >
                    <AnchorLink
                      to={`/services/#${service.section}`}
                      className={`transition-colors duration-300 ease-in-out text-sm lg:text-base px-2 py-2 flex flex-col items-center hover:text-primary ${
                        activeSection === index ? "text-primary" : "text-dark"
                      }`}
                    >
                      <Svg
                        src={service.navIcon.publicURL}
                        width="40"
                        height="40"
                        className="hidden md:block fill-current mb-3"
                      />
                      <span>{service.navTitle}</span>
                    </AnchorLink>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>

        {services.nodes.map((service, index) => {
          return (
            <div key={service.id} ref={sectionRefs[index]} id={service.section}>
              <ServiceSection
                background={index % 2 === 0 ? "bg-white" : "bg-beige"}
                header={service.title}
                subHeader={service.subtitle}
                lead={service.intro}
                icon={
                  <Svg
                    src={service.icon.publicURL}
                    className={`${
                      index % 2 === 0 ? "text-beige" : "text-white"
                    } stroke-current animated-icon`}
                  />
                }
              >
                {service.technologies && (
                  <div className="flex flex-wrap mt-6 md:mt-10">
                    {service.technologies.map((technology, index) => {
                      return (
                        <TechnologyItem
                          key={index}
                          label={technology.name}
                          url={technology.url}
                          icon={
                            <Svg
                              src={technology.icon.publicURL}
                              width="48px"
                              height="48px"
                            />
                          }
                        />
                      )
                    })}
                  </div>
                )}
                <ServicesGrid
                  background={index % 2 === 0 ? "bg-beige" : "bg-white"}
                  title="Services"
                  linkText="Learn more"
                  url={service.url}
                >
                  {service.services.map(service => {
                    return (
                      <div
                        key={service.title}
                        className="w-full sm:w-1/2 md:w-1/4 px-4"
                      >
                        <ServiceItem
                          justify="md:mr-auto"
                          lead={service.lead}
                          icon={
                            <Svg
                              src={service.icon.publicURL}
                              width="32px"
                              height="32px"
                              className="stroke-current"
                            />
                          }
                        >
                          {service.title}
                        </ServiceItem>
                      </div>
                    )
                  })}
                </ServicesGrid>
                <WorksGrid title="Works">
                  <div className="w-full works-carousel">
                    <Slider {...carouselSettings}>
                      {service.works.map((work, index) => {
                        return (
                          <div key={index} className="md:w-1/3 px-3">
                            <ServicesWorkBox
                              title={work.title}
                              lead={work.lead}
                              url={work.url}
                            >
                              <GatsbyImage
                                image={getImage(work.image)}
                                quality={100}
                                alt={work.title}
                              />
                            </ServicesWorkBox>
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                </WorksGrid>
              </ServiceSection>
            </div>
          )
        })}
      </section>
      <Testimonials
        header="Your journey with Peak11"
        subHeader="Testimonials"
      />
      <Brands />
      <AuthorisedVendor
        header="We are listed on the top review sites"
        subHeader="Verified company"
      ></AuthorisedVendor>
      <QuoteSection />
    </Layout>
  )
}

export default ServicesPage
