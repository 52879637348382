import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const TechnologyItem = ({ label, icon, url }) => {
  return (
    <>
      {url ?
        <Link to={`/${url}`} className="transition-transform duration-300 ease-in-out inline-flex flex-col items-center mt-6 mr-8 w-[70px] group">
          <div className="transition-shadow duration-300 ease-in-out inline-flex items-center justify-center rounded-lg shadow-[0_4px_12px_rgba(0,0,0,0.15)] w-12 group-hover:shadow-[0_4px_16px_rgba(0,0,0,0.20)]">
            {icon}
          </div>
          <div className="transition-colors duration-300 ease-in-out mt-4 text-sm text-dark text-center group-hover:text-primary">{label}</div>
        </Link>
        :
        <div className="inline-flex flex-col items-center mt-6 mr-8 w-[70px]">
          <div className="inline-flex items-center justify-center rounded-lg shadow-[0_4px_12px_rgba(0,0,0,0.15)] w-12">
            {icon}
          </div>
          <div className="mt-4 text-sm text-dark text-center">{label}</div>
        </div>
      }
    </>
  )
}

TechnologyItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  url: PropTypes.string,
}

TechnologyItem.defaultProps = {
  label: ``,
  icon: ``,
  string: ``
}

export default TechnologyItem
