import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const ServicesGrid = ({ background, title, linkText, url, children }) => {
  return (
    <div className={`${background} py-8 -mx-4 mt-10 md:mt-16`}>
      <div className="px-4">
        <div className="px-2">
          <h3 className="font-bold text-sm md:text-xl text-black mb-8 md:mb-14">
            {title}
          </h3>
          <div className="flex flex-wrap -mx-4">{children}</div>
          <div className="my-6 md:my-8">
            <Link
              to={url}
              className="text-primary text-base md:text-xl font-semibold relative group"
            >
              {linkText}
              <span className="transition-all duration-300 ease-in-out inline-block absolute left-0 -bottom-1 w-full h-0 bg-primary opacity-0 sm:group-hover:h-0.5 group-hover:opacity-100"></span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

ServicesGrid.propTypes = {
  background: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

ServicesGrid.defaultProps = {
  background: ``,
  title: ``,
  linkText: ``,
  url: ``,
  children: ``,
}

export default ServicesGrid
